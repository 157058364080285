.header {
  display: grid;
  overflow: hidden;
  background-color: #fff;
  padding-bottom: 10px;
  align-items: center;
  grid-template-rows: 100%;
  grid-template-columns: auto 1fr auto;
}

.project-name-wrapper {
  max-width: 10px;
  min-width: 10px;
}

.project-name-image {
  width: 150px;
  margin-left: 20px;
}

@media screen and (min-width: 768px) {
  .header {
    grid-template-columns: auto 1fr 2fr;
  }

  .project-name-wrapper {
    max-width: 150px;
    min-width: 150px;
    justify-self: right;
  }

  .project-name-image {
    width: auto;
    margin-left: 0;
  }
}

.drawer {
  padding-left: 10px;
  font-size: 50px;
  padding-top: 20px;
}
