.container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-parent {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 0.2fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.card-body-left-container {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  /*width: 83px;*/
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.card-body-right-container {
  grid-area: 1 / 2 / 2 / 5;
  padding: 10px 0 0 5px;
  text-align: left;
  font-weight: 500;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.card-title {
  grid-area: 1 / 1 / 1 / 3;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.card-value {
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 24px;
}

.card-action {
  color: red;
  align-self: center;
  justify-self: end;
  margin-right: 10px;
}

.card-action:hover {
  cursor: pointer;
}
