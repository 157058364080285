.sensor {
  color: black;
  display: flex;
  font-weight: bold;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.layer-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
