.sidebar-container {
  overflow-y: scroll;
}

.sidebar-item {
  padding-top: 20px;
  padding-bottom: 20px;
}

.active {
  color: grey;
  background-color: white;
}

/*@media (min-width: 768px) {*/
/*  .sidebar-container {*/
/*    overflow-y: hidden;*/
/*  }*/
/*}*/
