.settings-page {
  background-color: #f3f6f9;
}

.setting-section-list {
  display: grid;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.setting-section-list-item {
  padding: 20px;
  border-radius: 4px;
  border: 2px solid #1890ff;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .setting-section-list-item {
    padding: 30px;
  }
}
