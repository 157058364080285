.ant-table-title {
  padding: 0;
}

.total-count {
  margin-top: 20px;
  margin-left: 10px;
}
.text_lable {
  height: 40px;
  font-size: 12x;
  line-height: 40px;
  font-weight: bold;
}
.text_content {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}
