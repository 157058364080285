.floor-plan-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.floor-plan-wrapper {
  position: relative;
  overflow: auto;
  max-height: 700px;
}

.floor-plan-image {
  border: 1px solid lightgrey;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
