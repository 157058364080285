.container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.parent {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 0.2fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.left-container {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  width: 83px;
  justify-content: center;
  align-items: center;
}

.borde1 {
  border: 1px solid #e8e8e8;
  padding: 0px 0px 10px 10px;
  margin-top: 10px;
}
