#root {
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

body {
  overflow: hidden !important;
}

* {
  box-sizing: border-box;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #ffffff !important;
}

.ant-form-item-extra {
  color: #ff7f27 !important;
}

.ant-upload-drag-container {
  /* height: 300px; */
  height: 200px;
}

.ant-table {
  overflow-x: auto;
}

.ant-row {
  align-items: flex-start;
}

.p-10 {
  padding: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.d-flex {
  display: flex;
}

.offset-on-sm {
  margin-bottom: 10px;
}

.text-center {
  text-align: center;
}

.popover-content {
  color: #41cfff;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .offset-on-sm {
    margin-bottom: 10px;
  }
}

.zoom-button-align {
  position: absolute;
  top: 10px;
  left: 20px;
}

.ant-breadcrumb ol {
  padding-left: 0 !important;
}

.required:before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgb(100, 100, 100);
}

input:disabled {
  color: rgb(100, 100, 100) !important;
}
.ant-select-selection-overflow-item,
.ant-select-selector {
  color: rgb(100, 100, 100) !important;
}

.ant-checkbox-disabled + span {
  color: rgb(100, 100, 100) !important;
}
