.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginForm {
  padding: 30px;
  background-color: white;
}
