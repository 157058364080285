.event-cards-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  grid-auto-rows: 1fr;
}

.monthswitchcontainer {
  align-items: center;
  justify-items: center;
}

.date {
  font-weight: bold;
}

@media (min-width: 768px) {
  .event-cards-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 962px) {
  .event-cards-container {
    grid-template-columns: repeat(6, 1fr);
  }
}
