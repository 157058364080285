.report-grid {
  padding: 10px;
  background: white;
  overflow-x: scroll;
}

.report-row {
  display: flex;
}

.report-row .report-box {
  min-width: 100px;
  border: 1px solid black;
  justify-content: center;
  align-content: center;
  font-weight: 600;
  padding: 4px;
}

.report-row .report-box .subtitle {
  min-height: 20px;
  text-align: center;
}
