.time-wrapper {
  margin-top: 10px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  padding: 3px 15px;
  word-wrap: break-word;
  border-radius: 2px;
}

.demo-wrapper {
  /*width: 836px;*/
  /*height: 635px;*/
  position: relative;
}

/* animation player css */
.timeline-wrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  height: 30px;
  background: white;
  transition: 1s ease all;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

.heatmap-timeline {
  position: absolute;
  top: 0;
  right: 15px;
  left: 80px;
  height: 100%;
}

.heatmap-timeline .line {
  position: absolute;
  left: 0;
  right: 0;
  top: 15px;
  height: 2px;
  background: #d7d7d7;
}

.heatmap-timeline .time-point.active {
  background: black;
}

.heatmap-timeline .time-point {
  position: absolute;
  background: white;
  border: 2px solid #272727;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  cursor: pointer;
  top: 15px;
  transform: translateX(-50%) translateY(-50%);
}

.heatmap-timeline .time-point:hover {
  box-shadow: 0 0 5px black;
}

.timeline-wrapper button {
  position: absolute;
  outline: none;
  color: black;
  background: #f2f2f2;
  width: 65px;
  height: 100%;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.heatmap-timeline .time-point.active {
  background: black;
}

.legend-container {
  display: flex;
  flex-direction: column;
}

.legend {
  margin: 10px;
}

.rat-hit {
  background-color: rgb(0, 0, 255);
  color: white;
  padding: 5px;
  font-weight: bold;
  margin-right: 5px;
}

.disconnected {
  background-color: rgb(255, 0, 0);
  color: white;
  padding: 5px;
  font-weight: bold;
  margin-right: 5px;
}

.trap-caught {
  background-color: rgb(255, 0, 255);
  color: white;
  padding: 5px;
  font-weight: bold;
  margin-right: 5px;
}

.trap-missed {
  background-color: rgb(255, 255, 0);
  color: black;
  padding: 5px;
  font-weight: bold;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .legend-container {
    flex-direction: row;
    justify-content: end;
  }
}

.tooltip {
  background-color: black;
  color: white;
  z-index: 20;
  width: fit-content;
  position: absolute;
  padding: 10px;
  display: none;
}
