.graphs-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin: 10px;
}

.graphs-container1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin: 10px;
}

.graph-title {
  font-size: 25px;
  font-weight: bolder;
  text-align: center;
}

@media (min-width: 768px) {
  .graphs-container {
    grid-template-columns: 2fr 4fr 3fr;
  }

  .graphs-container1 {
    grid-template-columns: 3fr 3fr 3fr;
  }
}
