.content {
  height: auto;
  padding: 10px;
}

.headerRow {
  margin-bottom: 5%;
}

.table {
  width: 100%;
  overflow-x: scroll;
}

@media (min-width: 768px) {
  .table {
    overflow-x: hidden !important;
  }
}
