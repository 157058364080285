.mobile-header-right-container {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: flex-end;

  ul {
    width: 100%;
    border-bottom: none !important;
  }
}

.desktop-header-right-container {
  display: none;
}

@media screen and (min-width: 768px) {
  .desktop-header-right-container {
    height: 64px;
    width: 64px;
    display: flex;
    justify-content: flex-end;
    min-width: 500px;
    justify-self: right;

    ul {
      width: 100%;
      border-bottom: none !important;
    }
  }
  .mobile-header-right-container {
    display: none;
  }
}

.font-small {
  font-size: 14px;
}

.font-medium {
  font-size: 17px;
}

.font-large {
  font-size: 20px;
}
