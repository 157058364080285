.container {
  //display: grid;
  //grid-template-columns: 1fr;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
  background-color: white;

  &:hover {
    cursor: pointer;
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
      0 5px 12px 4px rgb(0 0 0 / 9%);
    transition: box-shadow 0.3s, border-color 0.3s;
  }

  .heading {
    text-align: center;
  }

  ul {
    list-style-type: none;
    padding: 10px;
  }

  li {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
  }
}

.marker {
  display: inline-block;
  width: 10px;
  height: 10px;
  content: '';
  margin-right: 5px;
}

.eventType1 {
  background-color: #ffe1ce;
}

.eventType2 {
  background-color: #d2efd0;
}

.eventType3 {
  background-color: #fff3be;
}

.eventType4 {
  background-color: blueviolet;
}

.eventType5 {
  background-color: deepskyblue;
}

.eventType6 {
  background-color: blue;
}

.eventType7 {
  background-color: orange;
}
