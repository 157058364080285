.sensor {
  color: black;
  font-weight: bold;
  vertical-align: middle;
  border: 1px solid black;
}

.layer-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
