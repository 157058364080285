.container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.parent {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 0.2fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.left-container {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  width: 83px;
  justify-content: center;
  align-items: center;
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.custom-container {
  border: 1px solid #e8e8e8;
  padding: 5px;
  margin-top: 10px;
}

.info-cards-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.info-card {
  border: 1px solid #e8e8e8;
  padding: 0 10px;
}

.info-card-list {
  list-style-type: none;
  padding: 0 3px;
  margin-top: 15px;
}

@media (min-width: 900px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .info-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    padding-top: 10px;
  }
}

@media (min-width: 1300px) {
  .cards-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
