.headerRow {
  display: flex;
  overflow: auto;
  justify-content: space-between;
}

.title {
  margin-bottom: 5px !important;
}

.headerBar {
  width: 50px;
  height: 10px;
  margin-bottom: 10px;
  background-color: lightgray;
}

.rightHeader {
  display: flex;
  column-gap: 10px;
  align-items: stretch;
}

@media screen and (min-width: 768px) {
  .rightHeader {
    justify-content: flex-end;
  }
}
