.collapsible .ant-collapse-header {
  overflow: hidden;
  background: rgb(65, 78, 142);
  border: 0;
  border-radius: 2px;
}

.collapsible .ant-collapse-content-box {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.header-table {
  background-color: rgb(65, 78, 142) !important;
}

.header-table td,
.header-table th {
  color: white !important;
  background-color: rgb(65, 78, 142) !important;
  border: none !important;
}
.table {
  width: 100%;
  overflow-x: scroll;
}
.pagination-container {
  padding-left: 16px;
  padding-right: 16px;
}

.pagination-container .message {
  display: block;
  margin-top: 10px;
}
