.ant-table-title {
  padding: 0;
}

.total-count {
  margin-top: 20px;
  margin-left: 10px;
}

.text_lable {
  font-size: 12px;
  font-weight: bold;
}

.text_content {
  font-size: 12px;
  text-wrap: normal;
}
