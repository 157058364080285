.mobile-side-bar {
  display: flex;
  column-gap: 15px;
  align-items: center;
  padding-left: 15px;

  .hamburger {
    font-size: 40px;
  }

  img {
    width: auto;
    height: 30px;
  }
}

.desktop-side-bar {
  display: none;

  img {
    width: auto;
    height: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-side-bar {
    display: none;
  }
  .desktop-side-bar {
    display: flex;
    column-gap: 15px;
    align-items: center;
    padding-left: 15px;
  }
}
