.layout-container {
  display: grid;
  height: 100%;
  background-color: #f3f6f9;
  grid-template-rows: auto auto 1fr;
}

.custom-layout-open {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 1fr;
  overflow-y: scroll;
}

.custom-layout-close {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow-y: scroll;
}

.page-content {
  overflow-y: scroll;
  max-height: calc(100vh - 64px);
  padding: 10px 10px 120px;
}

@media screen and (min-width: 576px) {
  .page-content {
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .custom-layout-open {
    overflow-y: hidden;
  }

  .custom-layout-close {
    overflow-y: hidden;
  }
}
