.content {
  background-color: #ffffff;
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
}

.notification-button {
  -webkit-appearance: none;
  background-color: transparent;
  border-width: 0;
}

.footer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

@media (min-width: 768px) {
  .footer {
    grid-template-columns: 1fr 4fr;
  }
}
