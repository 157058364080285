.table {
  width: 100%;
  overflow-x: scroll;
}

@media screen and (min-width: 576px) {
  .table {
    width: 100%;
    margin-top: 10px;
  }
}

.right-header {
  display: grid;
  column-gap: 8px;
  justify-content: flex-start;
  grid-template-columns: auto auto;
}

@media screen and (min-width: 768px) {
  .right-header {
    justify-content: flex-end;
  }
}
